<template>
  <div class="schedule">
    <div class="schedule-header">设置调度属性</div>
    <a-form-model class="form" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item ref="scheduletype" label="执行频率" prop="scheduletype">
        <a-radio-group v-model="form.scheduletype" @change="changeScedule">
          <a-radio-button value="timer">周期调度</a-radio-button>
          <a-radio-button value="once">一次性调度</a-radio-button>
          <a-radio-button value="manual">手动</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div v-if="form.scheduletype === 'timer'">
        <div class="flex-container">
          <div class="mgb10" :style="{'width': (5/24)*100+'%'}">
            <span v-if="source==='task'" style="color:red; display: inline-block;margin-right: 4px;">*</span><span v-else style="width: 4px; display: inline-block;margin-right: 5px;"></span>生效日期：
          </div>
          <a-form-model-item required prop="start_time" :class="[source === 'flow' && 'flow-date']" style="width: 30.65%" :labelCol="{span: 0}" :wrapperCol="{ span: 24 }">
            <a-date-picker v-model="form.start_time" style="width: 100%" show-time type="date" placeholder="开始日期时间" showTime format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" />
          </a-form-model-item>
          <a-form-model-item label :class="[source === 'flow' ?'flow-date mgl8' : 'mgl12']" required prop="end_time" style="width: 30.65%" :labelCol="{span: 0}" :wrapperCol="{ span: 24 }">
            <a-date-picker v-model="form.end_time" style="width: 100%" show-time type="date" placeholder="结束日期时间" showTime format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" :disabled="form.cronObj.forever" />
          </a-form-model-item>
          <a-form-model-item :class="[source === 'flow' ?'mgl8' : 'mgl16']" label required :prop="form.cronObj.forever ? 'end_timeForever' : 'forever'" :labelCol="{span: 0}" :wrapperCol="{ span: 24 }">
            <a-checkbox :true-value="true" :false-value="false" v-model="form.cronObj.forever">持续生效</a-checkbox>
          </a-form-model-item>
        </div>
        <a-form-model-item label="调度周期" :required="source==='task'">
          <a-radio-group v-model="custom">
            <a-radio-button value="1">自定义</a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="Cron表达式" prop="cron">
          <a-input style="width: 100%" v-model="form.cron" placeholder="请输入Cron表达式" :disabled="isTool" @change="changeCron" />
        </a-form-model-item>
        <a-form-model-item :wrapperCol="{ span: 19, offset: 5 }">
          <cron ref="cronRef" :form="form" @getIsTool="getIsTool" @getPreviewTime="getPreviewTime" />
        </a-form-model-item>
        <a-form-model-item label="时区">
          <a-select v-model="form.timezone">
            <a-select-option value="Asia/Shanghai">shanghai</a-select-option>
          </a-select>
        </a-form-model-item>
      </div>
      <div v-if="form.scheduletype === 'once'">
        <a-form-model-item required label="执行时间" prop="runtime">
          <a-date-picker v-model="form.runtime" style="width: 100%" show-time type="date" placeholder="请选择执行时间" showTime format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" />
        </a-form-model-item>
      </div>
      <div v-if="timePreview.length && form.scheduletype === 'timer'" class="flex-container" style="align-items: baseline">
        <div class="mgb10" :style="{'width': (5/24)*100+'%', paddingLeft: '11px'}">
         时间预览：
        </div>
          <div>
            <div class="cron-text">{{ cronText }}</div>
            <div class="time-preview">
              <span v-for="(item, index) in timePreview" :key="index">{{  item }}</span>
          </div>
        </div>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import cron from "./cron.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import moment from 'moment'

export default {
  components: { cron },
  name: "schedule",
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      other: "",
      custom: "1",
      isTool: false,
      timePreview: [],
      selectOptList: [],
      cronText: '',
      form: {
        cron: "",
        start_time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        end_time: undefined,
        scheduletype: "timer",
        timezone: "Asia/Shanghai",
        runtime: undefined,
        cronObj: {
          forever: true,
          interVal: "",
          dispatchType: "day",
          time: undefined,
          days: undefined,
          weeks: undefined,
        },
      },
      rules: {
        scheduletype: [
          {
            required: ['task', 'flow'].includes(this.source),
            trigger: "change",
          },
        ],
        end_time: [
          {
            required: ['task', 'flow'].includes(this.source),
            message: "请选择结束时间",
            trigger: ["change"],
          },
        ],
        end_timeForever: [
          {
            required: false,
            message: "请选择结束时间",
            trigger: ["change"],
          },
        ],
        start_time: [
          {
            required: ['task', 'flow'].includes(this.source),
            message: "请选择开始时间",
            trigger: ["change"],
          },
        ],
        cron: [
          {
            required: ['task', 'flow'].includes(this.source),
            message: "请输入Corn表达式",
            trigger: ["change"],
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    currentAppId() {
      const currentAppId = this.source === 'task' ? this.task.taskInfo?.taskTemplateDetail?.operations[this.task.selectTemplateIndex]?.application_id  :  this.task?.stepInfo?.taskTemplateDetail?.stepInfo?.properties?.application_id || ''
      return currentAppId
    },
    currentStepsIndex () {
      let currentStepsIndex = 0
      if (this.source === 'flow') currentStepsIndex =  this.task?.stepInfo?.taskTemplateDetail?.stepInfo?.properties['currentStepsIndex'] || 0;
      return currentStepsIndex
    },
  },
  props: {
    source: {
      type: String,
      default: 'task'
    }
  },
  watch: {
    "task.selectStepId": {
      handler(val) {
        if (val && this.task?.stepInfo?.taskTemplateDetail?.stepInfo.id === val && this.source === 'flow') {
          this.initScedule()
        }
      },
    },
    "currentStepsIndex": {
      handler(val) {
        if (val > 1 && this.source === 'flow') {
          this.initScedule()
        }
      },
      immediate: true
    },
    "task.selectTemplateIndex": {
      handler(val) {
        if (
          ['task'].includes(this.source) &&
          this.task &&
          this.task.taskInfo &&
          this.task.taskInfo.taskTemplateDetail &&
          this.task.taskInfo.taskTemplateDetail.operations &&
          this.task.taskInfo.taskTemplateDetail.operations.length > 0
        ) {
          this.initScedule()
        }
      },
      immediate: true,
    },
    form: {
      handler(val) {
        if (['task', 'flow'].includes(this.source)) {
         if (this.source === 'task') return this.syncTaskData();
         this.syncFlowData()
        } else {
          this.$emit('getData', val)
        }
      },
      deep: true,
      immediate: true
    },
  },
  mounted () {
    if (['task', 'flow'].includes(this.source)) this.getOptListByAppId()
  },
  methods: {
    ...mapActions("task", [
      "setTaskInfo",
      "setStepInfo",
      "setShowStepsList",
      "setSelectTemplateIndex",
      "setCurrentOptIndex",
      "setAddTemplateIndex",
      "setCurrentPageIndex",
      "changeFirstEdit"
    ]),
    initScedule () {
      if (this.source === 'task') {
        this.selectedTemplateObj = this.task.taskInfo.taskTemplateDetail.operations[this.task.selectTemplateIndex] || { name: "", description: "" };
      }
      if (this.source === 'flow') {
        this.selectedTemplateObj = this.task?.stepInfo?.taskTemplateDetail?.stepInfo?.properties || { name: "", description: "" };
      }
      if (this.selectedTemplateObj && this.selectedTemplateObj.model) {
        this.form = {
          ...this.selectedTemplateObj.model,
          cron: this.selectedTemplateObj.model?.cron || "",
          start_time: this.selectedTemplateObj.model?.start_time || moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          end_time: this.selectedTemplateObj.model?.cronObj?.forever && this.selectedTemplateObj.model?.start_time ? undefined : this.selectedTemplateObj.model?.end_time || undefined,
          scheduletype: ['timer', 'once', 'manual'].includes(this.selectedTemplateObj?.id) ? this.selectedTemplateObj?.id || "timer" : this.selectedTemplateObj?.model?.scheduletype,
          timezone: "Asia/Shanghai",
          runtime: this.selectedTemplateObj.model?.runtime || moment().add(1, "hours").format("YYYY-MM-DD HH:mm:ss"),
          cronObj: {
            forever:  this.selectedTemplateObj.model?.cronObj?.forever || true,
            interVal: this.selectedTemplateObj.model?.cronObj?.interVal ||"",
            dispatchType: this.selectedTemplateObj.model?.cronObj?.dispatchType || "day",
            time: this.selectedTemplateObj.model?.cronObj?.time || undefined,
            days: this.selectedTemplateObj.model?.cronObj?.days || undefined,
            weeks: this.selectedTemplateObj.model?.cronObj?.weeks || undefined,
          },
        };
        // 第一次编辑进来的时候去获取一下 正常在步骤里面操作的时候不关心
        if (this.task.firstEdit) {
          this.$nextTick(() => {
            if (this.form.scheduletype === 'timer' && this.form.cron && this.$refs.cronRef) {
              this.$refs.cronRef.previewTime()
            }
          })
        }
      }
    },
    getOptListByAppId() {
      if (!this.currentAppId) return false
      this.$axiosGet(global.API.getApp + "/" + this.currentAppId + "/operations").then(
        (res) => {
          if ([200, 201, 204, 202].includes(res.status)) {
            this.selectOptList = res.data.results;
          }
        }
      );
    },
    changeScedule (val) {
      if (this.source === 'task') return this.changeTaskScedule(val)
      if (this.source === 'flow') return this.changeFlowScedule(val)
    },
    changeTaskScedule (val) {
      const arr = this.selectOptList.filter(item => item.id === val.target.value)
      if (arr.length) {
        let operationList = _.cloneDeep(this.task?.taskInfo?.taskTemplateDetail?.operations);
        const selectItem = operationList[this.task.selectTemplateIndex]
        const obj = {
          ...selectItem,
          name: arr[0].name,
          id: arr[0].id,
          description: arr[0].description
        }
        operationList[this.task.selectTemplateIndex] = obj
        let taskInfo = _.cloneDeep(this.task.taskInfo);
        taskInfo.taskTemplateDetail.operations = operationList;
        this.setTaskInfo(taskInfo);
      }
    },
    changeFlowScedule (val) {
      const arr = this.selectOptList.filter(item => item.id === val.target.value)
      if (arr.length) {
        const stepInfo = _.cloneDeep(this.task.stepInfo)
        let selectItem =  stepInfo.taskTemplateDetail.stepInfo.properties
        stepInfo.taskTemplateDetail.stepInfo.properties = {
          ...selectItem,
          name: arr[0].name,
          id: arr[0].id,
          description: arr[0].description
        }
        this.setStepInfo(stepInfo);
        this.$EventBus.$emit('updateStepsProperties', stepInfo.taskTemplateDetail.stepInfo.properties)
      }
    },
    // 手动输入的时候将预览时间清除
    changeCron () {
      this.timePreview = []
      this.cronText = ''
      this.changeFirstEdit(false)
    },
    // 使用cron生成工具
    getIsTool(val) {
      this.isTool = val;
    },
    parseCronToText () {
      let enmuArr = []
      let filteredArray = []
      let namesArray = []
      let text = ''
      let interVal = 1
      const cron = this.form.cron
      const cronArr = cron.split(' ')
      if (cronArr.length !== 6) return this.text = ''
      let seconds = cronArr[0]
      let minutes = cronArr[1]
      let hour = cronArr[2]
      let day = cronArr[3]
      let weeks = cronArr[5]
      let month = cronArr[4]
      interVal = interVal > 0 ? interVal : 1
      hour = hour ? hour : 0
      minutes = minutes ? minutes : 0
      seconds = seconds ? seconds : 0
      if (seconds.includes('/')) {
        interVal = seconds.split('/').length > 1 ? seconds.split('/')[1] : 1
        text = `每${interVal}秒执行一次`;
      } else if (minutes.includes('*') || minutes.includes('/')) {
        interVal = minutes.split('/').length > 1 ? minutes.split('/')[1] : 1
        text = seconds === '0' ? `每${interVal}分钟执行一次` : `每${interVal}分钟${seconds}秒执行一次`;
      } else if (hour.includes('*') || hour.includes('/')) {
        interVal = hour.split('/').length > 1 ? hour.split('/')[1] : 1
        text = seconds === '0' ? `每${interVal}小时${minutes}分执行一次` : `每${interVal}小时${minutes}分${seconds}秒执行一次`;
      } else if (day.includes('*')) {
        interVal = day.split('/').length > 1 ? day.split('/')[1] : 1
        text = seconds === '0' ? `每${interVal}天${hour}时${minutes}分执行一次` :  `每${interVal}天${hour}时${minutes}分${seconds}秒执行一次`;
      } else if (day === '?') {
        // 周
        enmuArr = this.$refs.cronRef.loadTimeList(2)
        weeks = weeks.split(',').map(item => Number(item))
        weeks.map(item => {
          const index = enmuArr.findIndex(chlid => chlid.val === item)
          if (index > -1) filteredArray.push(enmuArr[index])
        })
        filteredArray.sort((a, b) => a.val - b.val);
        namesArray = filteredArray.map(item => item.title);
        if(namesArray.length) {
          text = seconds === '0' ? `每周${namesArray.join('、')}${hour}时${minutes}分执行一次` : `每周${namesArray.join('、')}${hour}时${minutes}分${seconds}秒执行一次`;
        } else {
          text = ''
        }
      } else if (!day.includes('*') && !day.includes('/') && day !== '?') {
        interVal = month.split('/').length > 1 ? month.split('/')[1] : 1
        enmuArr = this.$refs.cronRef.loadTimeList(3)
        day = day.split(',').map(item => item)
        day.map(item => {
          const index = enmuArr.findIndex(chlid => chlid.val === item)
          if (index > -1) filteredArray.push(enmuArr[index])
        })
        filteredArray.sort((a, b) => a.val - b.val);
        namesArray = filteredArray.map(item => {
          if (item.title === '最后一天') {
            return item.title
          } else {
            return item.title.substring(2)
          }
        });
        if(namesArray.length) {
          text =  seconds === '0' ?  `每${interVal}月${namesArray.join('、')}${hour}时${minutes}分执行一次` : `每${interVal}月${namesArray.join('、')}${hour}时${minutes}分${seconds}秒执行一次`;
        } else {
          text = ''
        }
      }
      this.cronText  = text
    },
    getPreviewTime (val) {
      if (!val || !(val && val.length)) {
        this.changeFirstEdit(false)
      } else {
        this.parseCronToText()
        this.changeFirstEdit(true)
      }
      this.timePreview = val
    },
    syncTaskData() {
      const currentIndex = this.task.selectTemplateIndex
      const taskInfo = _.clone(this.task.taskInfo)
      const taskTemplateDetail = taskInfo.taskTemplateDetail
      const operations = taskTemplateDetail.operations
      let form = _.cloneDeep(this.form)
      operations[currentIndex].model = {
        ...form,
        end_time: form.cronObj.forever && form.start_time ? moment(form.start_time).add(100, "years").format('YYYY-MM-DD HH:mm:ss') : form.end_time
      }
      operations[currentIndex].id = this.form.scheduletype
      operations[currentIndex].rules = {
        timer: ['scheduletype', 'start_time', 'end_time', 'cron'],
        once: ['runtime']
      }
      if (this.form.scheduletype  === 'timer') {
        operations[currentIndex].schema['schema']['required'] = ['scheduletype', 'start_time', 'end_time', 'cron']
      } else if (this.form.scheduletype  === 'once') {
        operations[currentIndex].schema['schema']['required'] = ['runtime']
      } else {
        operations[currentIndex].schema['schema']['required'] = []
      }
      this.setTaskInfo(taskInfo)
    },
    syncFlowData () {
      let form = _.cloneDeep(this.form)
      const stepInfo = _.cloneDeep(this.task.stepInfo)
      stepInfo.taskTemplateDetail.stepInfo.properties.model = {
        ...form,
        end_time: form.cronObj.forever && form.start_time ? moment(form.start_time).add(100, "years").format('YYYY-MM-DD HH:mm:ss') : form.end_time
      }
      stepInfo.taskTemplateDetail.stepInfo.properties.id = this.form.scheduletype
      stepInfo.taskTemplateDetail.stepInfo.properties.rules = {
        timer: ['scheduletype', 'start_time', 'end_time', 'cron'],
        once: ['runtime']
      }
      if (this.form.scheduletype  === 'timer') {
        stepInfo.taskTemplateDetail.stepInfo.properties.schema['schema']['required'] = ['scheduletype', 'start_time', 'end_time', 'cron']
      } else if (this.form.scheduletype  === 'once') {
        stepInfo.taskTemplateDetail.stepInfo.properties.schema['schema']['required'] = ['runtime']
      } else {
        stepInfo.taskTemplateDetail.stepInfo.properties.schema['schema']['required'] = []
      }
      this.setStepInfo(stepInfo)
      const name = this.form.scheduletype  === 'timer' ? '周期调度' : this.form.scheduletype  === 'once' ? '一次性调度' : '手动调度'
      this.$EventBus.$emit('updateStepsProperties', stepInfo.taskTemplateDetail.stepInfo.properties)
      this.$EventBus.$emit('updateStepsNameByStepId', name)
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
  },
};
</script>
<style lang="less" scoped>
.schedule {
  &-header {
    font-size: 16px;
    color: #1d202d;
    letter-spacing: 0;
    line-height: 28px;
    font-weight: 600;
    border-bottom: 1px dashed rgba(208, 214, 223, 1);
    padding-bottom: 8px;
    margin-bottom: 30px;
    margin-right: 64px;
  }
  .form {
    // padding-right: 64px;
    .flow-date {
      .ant-calendar-picker {
        width: auto!important;
        min-width: 180px!important;
      }
    }
  }
  /deep/ .ant-form-item-label > label {
    color: #34343c;
  }
  /deep/ .ant-radio-group {
    width: 100%;
    label {
      text-align: center;
      width: 33.3333%;
    }
  }
  .cron-text {
    background: #F7F8FA;
    padding: 10px 28px 0;
  }
  .time-preview{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    background: #F7F8FA;
    border-radius: 4px;
    padding: 2px 5px 10px;
    span {
      display: block;
      text-align: center;
      padding: 2px 0;
      width: 33%;
      border-right: 1px dashed rgba(230,232,236,1);
      &:nth-child(3n) {
        border-right: none;
      }
    }
  }
}
</style>