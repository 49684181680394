<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-08-30 15:34:17
 * @LastEditTime: 2023-09-07 16:31:24
 * @FilePath: \awx-ui\src\components\customization\cron.vue
-->
<template>
  <div class="cron" v-if="isTool">
    <a-form-model-item>
      <div class="flex-container">
        <a-input addon-before="每" type="Number" v-model="form.cronObj.interVal" :disabled="form.cronObj.dispatchType === 'week'" style="width: 120px;" />
        <!-- 1天 2周 3月 4小时 5分钟 6自定义 -->
        <a-select v-model="form.cronObj.dispatchType" style="width: 90px;" @change="changeDispatchType('type')">
          <a-select-option value="minutes">分钟</a-select-option>
          <a-select-option value="hour">时</a-select-option>
          <a-select-option value="day">天</a-select-option>
          <a-select-option value="week">周</a-select-option>
          <a-select-option value="month">月</a-select-option>
        </a-select>
        <a-select v-if="form.cronObj.dispatchType === 'month'" v-model="form.cronObj.days" mode="multiple" :maxTagCount="1" placeholder="请选择" style="width: 184px;">
          <a-select-option v-for="ls in loadTimeList(3)" :key="ls.val" :value="ls.val">{{ls.title}}</a-select-option>
        </a-select>
        <a-select v-if="form.cronObj.dispatchType === 'week'" v-model="form.cronObj.weeks" mode="multiple" :maxTagCount="1" placeholder="请选择" style="width: 180px;">
          <a-select-option v-for="ls in loadTimeList(2)" :key="ls.val" :value="ls.val">{{ls.title}}</a-select-option>
        </a-select>
        <a-time-picker v-model="form.cronObj.time" :valueFormat="formatTime" :format="formatTime" style="flex: 1" />
      </div>
    </a-form-model-item>
    <div class="footer flex-container">
      <a-button type="primary" @click="confirm">确定</a-button>
      <a-button class="mgl12" :disabled="!form.cron" @click="previewTime">验证Cron</a-button>
    </div>
  </div>
  <div v-else>
    <div class="footer flex-container">
      <a-button type="primary" @click="useTool">使用生成工具</a-button>
      <a-button class="mgl12" :disabled="!form.cron" @click="previewTime">验证Cron</a-button>
    </div>
  </div>
</template>

<script>
import ScheduleCron from "@/utils/cron";
import cronParser from 'cron-parser';
import moment from 'moment'
export default {
  props: {
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  model: {
    prop: "form",
    event: "change",
  },
  data() {
    return {
      formatTime: "HH:mm:ss",
      isTool: false,
    };
  },
  components: {},
  computed: {},
  watch: {
    "form.cronObj": {
      handler(val) {
        this.getFormatTime();
      },
      deep: true,
      immediate: true,
    },
    isTool: {
      handler(val) {
        this.$emit("getIsTool", val);
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    changeDispatchType (type) {
      if (!type) return false
      this.form.cronObj.time = undefined
      this.form.cronObj.days = undefined
      this.form.cronObj.weeks = undefined
    },
    useTool() {
      this.form.cronObj.dispatchType = 'day'
      this.form.cronObj.time = undefined
      this.form.cronObj.interVal = ''
      this.form.cronObj.days = undefined
      this.form.cronObj.weeks = undefined
      this.$emit('getPreviewTime', [])
      this.isTool = true;
    },
    previewTime () {
      const currentDate = this.form.start_time
      const endDate = this.form.cronObj.forever && this.form.start_time ? moment(this.form.start_time).add(100, "years").format('YYYY-MM-DD HH:mm:ss') : this.form.end_time
      console.log(currentDate, endDate)
      if (!currentDate || !endDate) {
        this.$emit('getPreviewTime', [])
        this.$message.warning('请选择生效日期')
      }
      try {
        if (currentDate && endDate) {
          const interval = cronParser.parseExpression(this.form.cron, {
            currentDate,
            endDate,
            tz: 'Asia/Shanghai',
          });
          const nextFiveTimes = Array.from({ length: 5 }, () => moment(interval.next().toDate()).format('YYYY-MM-DD HH:mm:ss'));
          this.$emit('getPreviewTime', nextFiveTimes ? nextFiveTimes : [])
        }
      } catch (e) {
        // 捕获异常，并设置错误信息
        this.$message.error(e.message)
        this.$emit('getPreviewTime', [])
      }
    },
    isNotEmpty(value) {
      return value !== null && value !== undefined && value !== '';
    },
    confirm() {
      const { cronObj } = this.form;
      if (cronObj.interVal && cronObj.interVal < 1) return false;
      const time = cronObj.time ? cronObj.time.split(':') : []
      let hour;
      let minutes;
      let seconds;
      if (cronObj.dispatchType === "hour" && time.length > 1) {
        minutes = parseInt(time[0], 10)
        seconds =  parseInt(time[1], 10)
      }
      if (cronObj.dispatchType === "minutes" && time.length > 0) {
        seconds = parseInt(time[0], 10)
      }
      if (time.length > 2) {
        hour = parseInt(time[0], 10)
        minutes =  parseInt(time[1], 10)
        seconds =  parseInt(time[2], 10)
      }
      // 没有选时刻时候直接默认0
      hour = hour ? hour : 0
      minutes = minutes ? minutes : 0
      seconds = seconds ? seconds : 0
      const CRON = new ScheduleCron(
        cronObj.dispatchType,
        cronObj.weeks,
        cronObj.days,
        hour,
        minutes,
        seconds,
        cronObj.interVal
      );
      // this.$emit('getPreviewTime', [])
      this.form.cron = CRON.toCron()
      this.isTool = false;
      this.previewTime()
    },
    loadTimeList(type) {
      let arr = [];
      if (type === 2) {
        arr = [
          { val: 0, title: "星期日" },
          { val: 1, title: "星期一" },
          { val: 2, title: "星期二" },
          { val: 3, title: "星期三" },
          { val: 4, title: "星期四" },
          { val: 5, title: "星期五" },
          { val: 6, title: "星期六" },
        ];
        return arr;
      }
      let sum = type === 5 ? 60 : type === 4 ? 24 : type === 3 ? 31 : 60;
      for (let i = 0; i < sum; i++) {
        let label = i;
        if (i < 10 && type !== 3) label = "0" + i;
        arr.push({
          title:
            type == 3
              ? i === 30
                ? "最后一天"
                : "每月" + Number(i + 1) + "号"
              : label,
          val: type == 3 ? (i === 30 ? "L" : Number(i + 1) + "") : i,
        });
      }
      return arr;
    },
    getFormatTime() {
      let formatTime = "HH:mm:ss";
      if (this.form.cronObj.dispatchType === "hour") formatTime = "mm:ss";
      if (this.form.cronObj.dispatchType === "minutes") formatTime = "ss";
      this.formatTime = formatTime;
    },
  },
};
</script>
<style scoped lang='less'>
.cron {
  /deep/ .ant-input {
    border-radius: 0;
  }
  /deep/ .ant-select-selection,
  /deep/ .ant-time-picker-input {
    border-radius: 0;
    border-left: none;
    position: relative;
    top: -1px;
  }
  /deep/ .ant-time-picker-input {
    border-radius: 0 3px 3px 0;
  }
}
</style>