/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-08-31 09:08:40
 * @LastEditTime: 2023-10-31 10:18:47
 * @FilePath: \awx-ui\src\utils\cron.js
 */
const cronParser = require('cron-parser');

export default class ScheduleCron {

  // 调度周期 'day天 'week'周 'month'月 'hour'小时 5分钟 6自定义
  scheduleCycle = 'day';
  // 每周那些天 周一、周二等等
  weeks = null;
  // 每月那些天 'day，'week'，'month'，'hour'，等等
  days = null;
  // 作业运行的具体小时（选择天、周、月）
  hour = null;
  // 作业运行的具体分钟（选择天、周、月）
  minutes = null;
  seconds = null;
  // 间隔
  interval = null;
  // 作业运行起始时间（小时）
  startHour = null;
  // 选择小时时，运行间隔时间
  hourInterval = null;
  // 作业运行起始时间（分钟）
  startMinutes = null;
  // 选择分钟时，运行间隔时间
  minutesInterval = null;
  // 作业运行起始时间（小时）
  endHour = null;
  // 作业运行起始时间（分钟）
  endMinutes = null;
  // 自定义调度周期时传入的调度表达式
  cron = null;
  constructor(scheduleCycle, weeks, days, hour, minutes, seconds, interval) {
    this.scheduleCycle = scheduleCycle;
    this.weeks = weeks;
    this.days = days;
    this.hour = hour;
    this.minutes = minutes;
    this.seconds = seconds;
    this.interval = interval;
  }
  // 时区
  /**
   * 根据配置转换为cron表达式
   *
   * @return cron表达式
   */
  toCron() {
    // 调度周期 'day天 'week'周 'month'月 'hour'小时 5分钟 6自定义
    // {秒} {分钟} {小时} {日期} {月份} {星期} {年份（可为空）}
    const seconds = this.seconds < 0 ? '*' : this.seconds
    const minutes = this.getExpression(this.interval, this.minutes, false)
    const hour = this.getExpression(this.interval, this.hour, true)
    const days = this.scheduleCycle == 'day' ? this.interval ? '*/' + this.interval : '*' : this.scheduleCycle == 'month' && (null != this.days && this.days.length) ? this.days.join(',') : (this.scheduleCycle == 'week' ? "?" : "*")
    const month = this.scheduleCycle == 'month' && this.interval ? '*/' + this.interval : '*'
    const weeks = this.scheduleCycle == 'week' && null != (this.weeks && this.weeks.length) ? this.interval ? this.weeks.join(',') + '/' + this.interval : this.weeks.join(',') : "?"
    return seconds + ' ' + minutes + ' ' + hour + ' ' + days + ' ' + month + ' ' + weeks
  }

  /**
   * 获取cron分钟和小时位置表达式
   *
   * @param start          开始时间
   * @param end            结束时间
   * @param interval       时间间隔
   * @param value          固定时间
   * @param isHourLocation 是否小时的位置
   * @return 表达式
   */
  getExpression(interval, value, isHourLocation) {
    // 'day天 'week'周 'month'月 'hour'小时 5分钟 6自定义
    switch (this.scheduleCycle) {
    case 'hour': // 小时，间隔的是时
      return isHourLocation ? interval ? '*/' + interval : '*' : value
    case 'minutes': // 分钟，间隔的是分
      return isHourLocation ? '*' : interval ? '*/' + interval : '*'
    case 'week': // 周，没有分钟和小时的选择
    case 'month': // 月，没有分钟和小时的选择
      return isHourLocation ? this.hour + '' : this.minutes + ''; // 设置具体的执行时间
    default: // 天，有小时和分钟的选择
      return value + '';
    }
  }

  cronToDescription(cronExpression) {
    try {
      const interval = cronParser.parseExpression(cronExpression);
      const description = {
        minute: this.getMinuteDescription(interval),
        hour: this.getHourDescription(interval),
        dayOfMonth: this.getDayOfMonthDescription(interval),
        month: this.getMonthDescription(interval),
        dayOfWeek: this.getDayOfWeekDescription(interval)
      };
      console.log(interval.fields, 'fieldsfields')
      return description;
    } catch (error) {
      console.error('无法解析cron表达式', error);
      return null;
    }
  }

  getMinuteDescription(interval) {
    const minuteField = interval.fields.find(field => field.name === 'minute');
    return this.getFieldDescription(minuteField);
  }

  getHourDescription(interval) {
    const hourField = interval.fields.find(field => field.name === 'hour');
    return this.getFieldDescription(hourField);
  }

  getDayOfMonthDescription(interval) {
    const dayOfMonthField = interval.fields.find(field => field.name === 'dayOfMonth');
    return this.getFieldDescription(dayOfMonthField);
  }

  getMonthDescription(interval) {
    const monthField = interval.fields.find(field => field.name === 'month');
    return this.getFieldDescription(monthField);
  }

  getDayOfWeekDescription(interval) {
    const dayOfWeekField = interval.fields.find(field => field.name === 'dayOfWeek');
    return this.getFieldDescription(dayOfWeekField);
  }

  getFieldDescription(field) {
    if (field.all()) {
      return `每${field.name}`;
    }
    const parsedExpressions = [];

    field.expressions.forEach(expression => {
      if (expression instanceof cronParser.Range) {
        parsedExpressions.push(this.parseRangeExpression(expression));
      } else if (expression instanceof cronParser.Step) {
        parsedExpressions.push(this.parseStepExpression(expression));
      } else if (expression instanceof cronParser.List) {
        parsedExpressions.push(this.parseListExpression(expression));
      } else if (expression instanceof cronParser.Value) {
        parsedExpressions.push(this.parseValueExpression(expression));
      }
    });

    return parsedExpressions.join(', ');
  }

  parseRangeExpression(range) {
    return `从${range.start}到${range.end}`;
  }

  parseStepExpression(step) {
    return `每隔${step.step}个${step.name}从${step.start}开始`;
  }

  parseListExpression(list) {
    const values = list.values.join(', ');
    return `${values} ${list.name}`;
  }

  parseValueExpression(value) {
    return `${value.value} ${value.name}`;
  }

}